const HOST = process.env.REACT_APP_HOST_ADDRESS + process.env.REACT_APP_API_VERSION

export const SIGNIN = HOST + "signin/"
export const LOGOUT = HOST + "logout/"
export const SIGNUP = HOST + "signup/"
export const FORGOT_PASSWORD = HOST + "forgot-password/"
export const RESET_PASSWORD = HOST + "reset-password/"
export const ACCOUNT = HOST + "account/"
export const PROMPT = HOST + "prompt/"
export const RESPONSE = HOST + "response/"
export const PAYMENT = HOST + "payment/"
export const CREATE_CHECKOUT_SESSION = HOST + "create-checkout-session/"
export const PRODUCT = HOST + "product/"

import React, { useState, useEffect } from 'react'

import axios from 'axios'

import { Box } from '@mui/material'

import Header from '../header/Header'
import Footer from '../footer/Footer'
import PromptList from './PromptList'

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Title from './Title'
import NewOrEditPrompt from './NewOrEditPrompt'

import { GopherActionButton, GopherMobileActionButton } from '../gopher/button'
import * as theme from '../../theme'
import { GopherAPILoader } from '../gopher/loading'

import { PROMPT } from '../../api_endpoints'
import NewPrompt from './NewPrompt'
import { useNavigate } from 'react-router-dom'


export default function Prompt({user, token, setUser, removeToken, removeUser, setLoading, newPrompt}) {
  const navigate = useNavigate()
  const [apiLoading, setAPILoading] = useState(false)
  const [promptState, setPromptState] = useState({
    list: true,
    update: false
  })
  const [promptsData, setPromptsData] = useState([])
  const [selectedPrompt, setSelectedPrompt] = useState({})

  const loadPrompts = () => {
    setAPILoading(true)
    axios.get(PROMPT, { withCredentials: false, headers: {  Authorization: 'Bearer ' + token } }, )
    .then(response => response.data)
    .then(response => {
      setAPILoading(false)
      const prompts = response.data
      setPromptsData(prompts)
    })
    .catch(error => {
      setAPILoading(false)
      const errorResponse = error.response
      if (errorResponse) if (errorResponse.data.token_expired) {
        removeUser()
        removeToken()
      }

     
      }
    )
  }
  // Read all available prompts
  useEffect(() => {
    loadPrompts()
  }, [])

  const handleReturn = () => {
    setPromptState({
      list: true,
      update: false
    })
    loadPrompts()
  }
  
  const handleReturnToPrompts = () => {
    navigate("/prompt")
    loadPrompts()
  }

  const handleNewPromptState = (reset) => {
    if (reset) window.location.reload()
    else navigate("/prompt/new")
    
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header type='prompt' user={user} removeToken={removeToken} removeUser={removeUser} setLoading={setLoading} />
      <Box sx={{ flexGrow: 1, backgroundColor: theme.BODY_BG, color: theme.BODY_FONT, pb: 5}} >
        {
          
          newPrompt?
          <>
            <Title text={"New Prompt"} buttons={[<GopherActionButton disabled={apiLoading} onClick={() => handleNewPromptState(true)} cursor="pointer" buttonText={"New Propmt"} startIcon={<AddCircleOutlineIcon />} key={1} sx={{ml:1}} />, <GopherActionButton disabled={apiLoading} onClick={ () =>  handleReturnToPrompts() } cursor="pointer" buttonText={"Return"} startIcon={<ArrowBackIcon />} key={1} sx={{ml:1}} />]} mobileButtons={[<GopherMobileActionButton  disabled={apiLoading} onClick={() => handleNewPromptState(true)} cursor="pointer" buttonText={"New"} startIcon={<AddCircleOutlineIcon />} key={1} sx={{ml:1}} />, <GopherMobileActionButton disabled={apiLoading} onClick={ () =>  handleReturnToPrompts() } cursor="pointer" buttonText={"Return"} startIcon={<ArrowBackIcon />} key={1} sx={{ml:1}} />]} />
            <NewPrompt user={user} setUser={setUser} token={token} setAPILoading={setAPILoading} />
            
          </>:
          (
          promptState.list?
          <>
            <Title text={"Prompt History"} buttons={[<GopherActionButton onClick={() => handleNewPromptState(false)} cursor="pointer" buttonText={"New Propmt"} startIcon={<AddCircleOutlineIcon />} key={1} sx={{ml:1}} />]} mobileButtons={[<GopherMobileActionButton onClick={() => handleNewPromptState(false)} cursor="pointer" buttonText={"New"} startIcon={<AddCircleOutlineIcon />} key={1} sx={{ml:1}} />]} />
            <GopherAPILoader loading={apiLoading} />
            <PromptList user={user} promptsData={promptsData} setPromptState={setPromptState} setSelectedPrompt={setSelectedPrompt} />
          </>:
          promptState.update?
          <>
            <Title text={"Update Prompt"} buttons={[<GopherActionButton disabled={apiLoading} onClick={() => handleNewPromptState(false)} cursor="pointer" buttonText={"New Propmt"} startIcon={<AddCircleOutlineIcon />} key={1} sx={{ml:1}} />, <GopherActionButton disabled={apiLoading} onClick={ () => handleReturn() } cursor="pointer" buttonText={"Return"} startIcon={<ArrowBackIcon />} key={1} sx={{ml:1}} />]} mobileButtons={[<GopherMobileActionButton  disabled={apiLoading} onClick={() => handleNewPromptState(false)} cursor="pointer" buttonText={"New"} startIcon={<AddCircleOutlineIcon />} key={1} sx={{ml:1}} />, <GopherMobileActionButton disabled={apiLoading} onClick={ () =>  handleReturn() } cursor="pointer" buttonText={"Return"} startIcon={<ArrowBackIcon />} key={1} sx={{ml:1}} />]} />
            <NewOrEditPrompt user={user} setUser={setUser} token={token} setAPILoading={setAPILoading} promptData={selectedPrompt} promptId_={selectedPrompt.id} />
          </>: 
          <></>
          )
        }
      </Box>  
      <Footer disclaimer />
    </div>
  )
}

import React from 'react'

import { Box, CircularProgress } from '@mui/material'

import * as theme from '../../../theme'

export default function GopherAPILoader({loading=false}) {
  return (
    <Box
      display={loading?"flex":"none"}
      justifyContent="center"
      alignItems="center"
      sx={{my:5}}
    >
      <CircularProgress sx={{ color: theme.BODY_FONT }} />
    </Box>
  )
}

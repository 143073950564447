import { Box, Typography, Modal, Divider } from '@mui/material'

import * as theme from '../../theme'
import { useState } from 'react'

import { GopherActionButton } from '../gopher/button'

export default function Footer({disclaimer}) {
  const [dislaimerOpen, setDisclaimerOpen] = useState(false)

  return (
    <Box sx={{ backgroundColor: theme.FOOTER_BG, color: theme.FOOTER_FONT , boxShadow: 20, py: 2 }}>
      <Typography>
        Copyright - NTI Blockchain, Inc. 2023 
        {
          disclaimer && 
          <label style={{marginLeft:5}}>
           - <label style={{ textDecoration: 'underline', cursor: 'pointer', marginLeft:5 }} onClick={() => setDisclaimerOpen(true)}>Disclaimer</label> 
          </label> 
        }
      </Typography>

      
     
      
      {/* Disclaimer Modal */}
      <Modal
        open={dislaimerOpen}
        onClose={() => setDisclaimerOpen(false)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{
          bgcolor: 'white',
          padding: 4,
          margin: 4,
          maxHeight: '80vh',
          overflowY: 'auto',
        }}>
          <Typography variant="h5" id="modal-title" gutterBottom>
            Disclaimer
          </Typography>

          <Divider sx={{ backgroundColor: "#000000", mb:2, borderWidth: 2}} />
          <Typography variant="body1" id="modal-description">
            GopherGet is a software solution designed to assist users in
            creating prompts for the ChatGPT language model. While every effort
            has been made to ensure the accuracy and reliability of the
            software, we would like to emphasize the following disclaimer:
            <br />
            <br />
            <strong> 1. Limitations of Language Model: </strong> GopherGet utilizes the ChatGPT
            language model, which is trained on a diverse range of internet
            text. While it can generate creative and contextually relevant
            responses, it may occasionally produce incorrect or nonsensical
            answers. Users should exercise caution and verify the generated
            prompts before use.
            <br />
            <strong> 2. User Responsibility: </strong> Users of GopherGet are solely responsible
            for the content and context of the prompts they create using the
            software. It is essential to review and refine the generated prompts
            to align with their intended purpose. We do not assume any liability
            for the consequences of using or relying upon the prompts created
            with GopherGet.
            <br />
            <strong> 3. No Legal or Professional Advice: </strong> GopherGet is a tool for prompt
            generation and does not provide legal, professional, or expert
            advice. The software should not be considered a substitute for
            professional judgment or consultation. Users are advised to consult
            appropriate experts or authorities for specific guidance related to
            their particular use case.
            <br />
            <strong> 4. Security Considerations: </strong> While we have implemented measures to
            ensure the security of GopherGet, including data encryption and
            protection, we cannot guarantee the complete security of
            user-generated data. Users are responsible for taking necessary
            precautions to protect sensitive information when using the software.
            <br />
            <strong> 5. Updates and Modifications: </strong> The GopherGet software may undergo
            updates and modifications over time to improve functionality,
            address issues, or incorporate new features. We reserve the right to
            make these changes without prior notice. Users are encouraged to
            regularly check for updates and use the latest version of the
            software.
            <br />
            <strong> 6. Use at Your Own Risk: </strong> GopherGet is provided on an "as is" basis,
            without any warranties or guarantees of any kind, expressed or
            implied. We disclaim any liability for any direct, indirect,
            incidental, or consequential damages arising out of the use or
            inability to use GopherGet, including but not limited to errors,
            inaccuracies, or data loss.
            <br />
            <br />
            By using GopherGet, users acknowledge and agree to the terms of this
            disclaimer. If you do not agree with any part of this disclaimer,
            please refrain from using GopherGet.
          </Typography>
          
          <GopherActionButton sx={{mt:4}} onClick={() => setDisclaimerOpen(false)} buttonText={"Close"} />
        </Box>
      </Modal>
    </Box>
  )
}

import * as React from 'react';

import { useNavigate } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PersonIcon from '@mui/icons-material/Person';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import AccountCircle from '@mui/icons-material/AccountCircle';

import logo from '../../logo.png';
import * as theme from '../../theme'
import { Badge, Typography } from '@mui/material';


const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));



export default function Header({type = "home", user, removeToken, removeUser, setLoading}) {

  const navigate = useNavigate()
  const [accountMenu, setAccountMenu] = React.useState(null);


  const handleMenu = (event) => {
    setAccountMenu(event.currentTarget);
  };

  const handleClose = () => {
    setAccountMenu(null);
  };

  const handleLogout = () => {
    handleClose()
    removeToken()
    removeUser()
    navigate("/auth")
    setLoading({
      state: true,
      headline: "Logging Out",
      paragraph: "We hope to see you soon"
    }
    )
    setTimeout(() =>{
      setLoading({state:false, headline: "", paragraph: ""})
    }, 1500)
  }
  
  const handleAccount = () => {
    handleClose()
    navigate("/account")
    
  }

  const isTokenBalanceGreaterThanOne = user? parseInt(user.token_balance, 10) > 1: null

  return (
    <Box>
      {/* Desktop version */}
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <AppBar position="static" sx={{ backgroundColor: theme.HEADER_BG, color: theme.HEADER_FONT, py: 1 }}>
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button edge="start" color="inherit" style={{fontSize: 20}} sx={{ mr: 2 }}>
             
            { type==="prompt" &&  <img src={logo} alt="Logo" width={50} style={{ marginRight: 5 }} /> }
            GopherGet AI Digger Tool
            </Button>
            
            
              {
                type === "auth"?
                <Button onClick={() => navigate("/")} color="inherit">
                  <ArrowBackIcon sx={{mr: 1}} /> Back To Home Page
                </Button>:
                type === "home"?
                <Button onClick={() => navigate("/auth")} color="inherit">
                  <PersonIcon sx={{mr: 1}} /> New Account / Login
                </Button>:
                type === "prompt"?
                <div>
                  <IconButton
                  onClick={handleMenu}
                  color="inherit"
                >
                 
                  {
                    isTokenBalanceGreaterThanOne  ? 
                    <AccountCircle sx={{ width: 50, height: 50 }} />
                   : 
                    
                    <StyledBadge badgeContent={<span>1</span>}>
                      <AccountCircle sx={{ width: 50, height: 50 }} />
                    </StyledBadge> 
                  }
                </IconButton>
                  <Menu
                  sx={{ display: { xs: 'none', md: 'block' } }}
                    id="menu-appbar"
                    anchorEl={accountMenu}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(accountMenu)}
                    onClose={handleClose}
                  >
                    <MenuItem sx={{color:isTokenBalanceGreaterThanOne  ? 
                    "black": theme.WARNING_BG, fontWeight:isTokenBalanceGreaterThanOne  ? 
                    "normal": 'bold'}} onClick={handleAccount}>
                      
                        Account
                  
                      </MenuItem>
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </Menu>
                </div>:
                ""
              }
          </Toolbar>
        </AppBar>
      </Box>

      {/* Mobile Version */}
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <Box sx={{ flexGrow: 1 }} >
          <AppBar position="static" sx={{ backgroundColor: theme.HEADER_BG, color: theme.HEADER_FONT, py: 1 }}>
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button  edge="start" color="inherit" style={{fontSize: 20}} sx={{ mr: 2 }}>
             
             { type==="prompt" &&  <img src={logo} alt="Logo" width={50} style={{ marginRight: 5 }} /> }
             GopherGet
             </Button>
              {
                type === "auth"?
                <Button onClick={() => navigate("/")} color="inherit">
                  <ArrowBackIcon sx={{mr: 1}} /> Home
                </Button>:
                type === "home"?
                <Button onClick={() => navigate("/auth")} color="inherit">
                  <PersonIcon sx={{mr: 1}} /> Signin
                </Button>:
                type === "prompt"?
                <div>
                  <IconButton
                  onClick={handleMenu}
                  color="inherit"
                >
                  {
                  isTokenBalanceGreaterThanOne  ? 
                  <AccountCircle sx={{ width: 50, height: 50 }} />
                 : 
                  
                  <StyledBadge badgeContent={<span>1</span>}>
                    <AccountCircle sx={{ width: 50, height: 50 }} />
                  </StyledBadge> 
                }
                
                </IconButton>
                  <Menu
                    sx={{
                      display: { xs: 'block', md: 'none' }
                    }}
                    id="menu-appbar"
                    anchorEl={accountMenu}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(accountMenu)}
                    onClose={handleClose}
                  >
                    <MenuItem sx={{color:isTokenBalanceGreaterThanOne  ? 
                    "black": theme.WARNING_BG, fontWeight:isTokenBalanceGreaterThanOne  ? 
                    "normal": 'bold'}} onClick={handleAccount}>
                      
                        Account
                  
                      </MenuItem>
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </Menu>
                </div>:
                ""
              }
            </Toolbar>
          </AppBar>
        </Box>
      </Box>

    </Box>
  );
}

import { useState } from 'react'

import { useNavigate } from 'react-router-dom';

import axios from 'axios';

import { Box, Button, Checkbox, Divider, FormControlLabel, Modal, Typography } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { GopherActionButton, GopherSubmitButton } from '../gopher/button';
import { GopherTextField } from '../gopher/field';
import * as theme from '../../theme'
import logo from '../../logo.png';

import { FORGOT_PASSWORD, SIGNIN, SIGNUP } from '../../api_endpoints';
import { GopherAlert } from '../gopher/alert';

const defaultTheme = createTheme();

export default function AuthenticationBody({setToken, setUser, setLoading}) {
 const navigate = useNavigate()
 const [state, setState] = useState({
  signIn: true,
  signUp: false,
  forgotPassword: false,
 });
 const [dislaimerOpen, setDisclaimerOpen] = useState(false);
 const [apiLoading, setAPILoading] = useState(false);
 const [signinFormValues, setSigninFormValues] = useState({})
 const [signupFormValues, setSignupFormValues] = useState({})
 const [forgotPasswordFormValues, setForgotPasswordFormValues] = useState({})
 const [alert, setAlert] = useState({
  open: false,
  title: "",
  message: ""
 });

 const handleAlertOpen = (title, message) => {
  setAlert({
    open: true,
    title: title,
    message: message
  });
 };

 const handleAlertClose = () => {
  setAlert({
    open: false,
    title: "",
    message: ""
  });
 };


 const handleSigninFormSubmit = (event) => {
  event.preventDefault()
  setAPILoading(true)
  axios.post(
      SIGNIN, 
      {
          "email": signinFormValues.email,
          "password": signinFormValues.password
      }, 
      {
          headers: { 'Content-Type': 'application/json' } 
      }
  )
  .then(response => response.data)
  .then(data => {
    
      setAPILoading(false)
      const accessToken = data.token
      const userData = data.user
      setToken(accessToken)
      setUser(userData)
      navigate("/prompt")  
      setLoading({
        state: true,
        headline: "Welcome to Gopher Get",
        paragraph: ""
      }
      )
      setTimeout(() =>{
        setLoading({state:false, headline: "", paragraph: ""})
      }, 1500)
        

  })
  .catch(error => {
    setAPILoading(false)
    const errorResponse = error.response
    handleAlertOpen("Error", errorResponse!==undefined?errorResponse.data.message:error.message)
  });

 }

 const handleSignupFormSubmit = (event) => {
  event.preventDefault()
  setAPILoading(true)
  axios.post(
      SIGNUP, 
      {
          "first_name": signupFormValues.first_name,
          "last_name": signupFormValues.last_name,
          "email": signupFormValues.email,
          "password": signupFormValues.password
      }, 
      {
          headers: { 'Content-Type': 'application/json' } 
      }
  )
  .then(response => response.data)
  .then(data => {
    setAPILoading(false)
      handleAlertOpen("Success", data.message)   
      setSignupFormValues({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
      })
  })
  .catch(error => {
    setAPILoading(false)
    const errorResponse = error.response
    handleAlertOpen("Error", errorResponse!==undefined?errorResponse.data.message:error.message)

  });

 }

 const handleForgotPasswordFormSubmit = (event) => {
  event.preventDefault()
  setAPILoading(true)
  axios.post(
      FORGOT_PASSWORD, 
      {
          "email": forgotPasswordFormValues.email,
      }, 
      {
          headers: { 'Content-Type': 'application/json' } 
      }
  )
  .then(response => response.data)
  .then(data => {
    setAPILoading(false)
      handleAlertOpen("Success", data.message)   
      setForgotPasswordFormValues({
        email: '',
        
      })
  })
  .catch(error => {
    setAPILoading(false)
    const errorResponse = error.response
    handleAlertOpen("Error", errorResponse!==undefined?errorResponse.data.message:error.message)

  });

 }
 
 const handleSigninFormChange = (event) => {
  setSigninFormValues((prevValues) => ({
      ...prevValues,
      [event.target.name]: event.target.value,
  }))
}

const handleSignupFormChange = (event) => {
  setSignupFormValues((prevValues) => ({
      ...prevValues,
      [event.target.name]: event.target.value,
  }))
}

const handleForgotPasswordFormChange = (event) => {
  setForgotPasswordFormValues((prevValues) => ({
      ...prevValues,
      [event.target.name]: event.target.value,
  }))
}

  const handleState = ({signIn, signUp, forgotPassword}) => {
    setState({
      signIn: signIn,
      signUp: signUp,
      forgotPassword: forgotPassword
    })
  }
 
  return (
    <>
      <GopherAlert alert={alert} handleAlertClose={handleAlertClose} />
      {/* Desktop + Mobile version */}
      <Box sx={{ display: { xs: 'block', md: 'block' } }}>
        <Box display="flex" justifyContent="center" alignItems="center"  >
          <Box padding={5} textAlign="center"  sx={{my:2}}>
            <ThemeProvider theme={defaultTheme}>

              <Container component="main"  sx={{
                display: state.signUp?"flex":"none",
                minHeight: '60vh',
               }}  id='signup' maxWidth="xs" >
                <CssBaseline />
                <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                    <img src={logo} alt="Logo" width={120} />
                  
                  <Typography component="h1" variant="h5">
                    Sign up
                  </Typography>
                  <Box component="form"  onSubmit={handleSignupFormSubmit} sx={{ mt: 3, color:theme.BODY_FONT }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <GopherTextField
                          name="first_name"
                          required
                          fullWidth
                          id="firstName"
                          label="First Name"
                          value={signupFormValues.first_name}
                          onChange={handleSignupFormChange} 
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <GopherTextField
                          required
                          fullWidth
                          id="last_name"
                          label="Last Name"
                          name="last_name"
                          value={signupFormValues.last_name}
                          onChange={handleSignupFormChange} 
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <GopherTextField
                          required
                          fullWidth
                          id="email"
                          label="Email Address"
                          name="email"
                          type="email"
                          value={signupFormValues.email}
                          onChange={handleSignupFormChange} 
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <GopherTextField
                          required
                          fullWidth
                          name="password"
                          label="Password"
                          type="password"
                          id="password"
                          value={signupFormValues.password}
                          onChange={handleSignupFormChange} 
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <FormControlLabel
                        required
                          control={<Checkbox sx={{
                            '&.Mui-checked': {
                              color: theme.PRIMARY_BUTTON,
                            },
                            color: theme.PRIMARY_BUTTON_FONT,
                          }}  />}
                          label={
                            <label>
                              I have read the <label style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setDisclaimerOpen(true)}>disclaimer</label> 
                          </label>
                          }
                        />
                      </Grid>
                    </Grid>
                    <GopherSubmitButton
                      fullWidth
                      loading={apiLoading}
                      variant="contained"
                      buttonText={"Sign Up"}
                      sx={{my:2}}
                      
                    />
                    <Grid container justifyContent="center">
                      <Grid item>
                        <Button onClick={() => handleState({signIn:true})}  variant="body2">
                          Already have an account? Sign in
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>

                  {/* Disclaimer Modal */}
                  <Modal
                    open={dislaimerOpen}
                    onClose={() => setDisclaimerOpen(false)}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                  >
                    <Box sx={{
                      bgcolor: 'white',
                      padding: 4,
                      margin: 4,
                      maxHeight: '80vh',
                      overflowY: 'auto',
                    }}>
                      <Typography variant="h5" id="modal-title" gutterBottom>
                        Disclaimer
                      </Typography>

                      <Divider sx={{ backgroundColor: "#000000", mb:2, borderWidth: 2}} />
                      <Typography variant="body1" id="modal-description">
                        GopherGet is a software solution designed to assist users in
                        creating prompts for the ChatGPT language model. While every effort
                        has been made to ensure the accuracy and reliability of the
                        software, we would like to emphasize the following disclaimer:
                        <br />
                        <br />
                        <strong> 1. Limitations of Language Model: </strong> GopherGet utilizes the ChatGPT
                        language model, which is trained on a diverse range of internet
                        text. While it can generate creative and contextually relevant
                        responses, it may occasionally produce incorrect or nonsensical
                        answers. Users should exercise caution and verify the generated
                        prompts before use.
                        <br />
                        <strong> 2. User Responsibility: </strong> Users of GopherGet are solely responsible
                        for the content and context of the prompts they create using the
                        software. It is essential to review and refine the generated prompts
                        to align with their intended purpose. We do not assume any liability
                        for the consequences of using or relying upon the prompts created
                        with GopherGet.
                        <br />
                        <strong> 3. No Legal or Professional Advice: </strong> GopherGet is a tool for prompt
                        generation and does not provide legal, professional, or expert
                        advice. The software should not be considered a substitute for
                        professional judgment or consultation. Users are advised to consult
                        appropriate experts or authorities for specific guidance related to
                        their particular use case.
                        <br />
                        <strong> 4. Security Considerations: </strong> While we have implemented measures to
                        ensure the security of GopherGet, including data encryption and
                        protection, we cannot guarantee the complete security of
                        user-generated data. Users are responsible for taking necessary
                        precautions to protect sensitive information when using the software.
                        <br />
                        <strong> 5. Updates and Modifications: </strong> The GopherGet software may undergo
                        updates and modifications over time to improve functionality,
                        address issues, or incorporate new features. We reserve the right to
                        make these changes without prior notice. Users are encouraged to
                        regularly check for updates and use the latest version of the
                        software.
                        <br />
                        <strong> 6. Use at Your Own Risk: </strong> GopherGet is provided on an "as is" basis,
                        without any warranties or guarantees of any kind, expressed or
                        implied. We disclaim any liability for any direct, indirect,
                        incidental, or consequential damages arising out of the use or
                        inability to use GopherGet, including but not limited to errors,
                        inaccuracies, or data loss.
                        <br />
                        <br />
                        By using GopherGet, users acknowledge and agree to the terms of this
                        disclaimer. If you do not agree with any part of this disclaimer,
                        please refrain from using GopherGet.
                      </Typography>
                      
                      <GopherActionButton sx={{mt:4}} onClick={() => setDisclaimerOpen(false)} buttonText={"Close"} />
                    </Box>
                  </Modal>
                </Box>
              </Container>
              
              <Container component="main"  sx={{
                display: state.signIn?"flex":"none",
                minHeight: '60vh',
                }} id='signin' maxWidth="xs">
                <CssBaseline />
                <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                >
                  
                  <img src={logo} alt="Logo" width={120} />
                  <Typography component="h1" variant="h5">
                    Sign in
                  </Typography>
                  <Box component="form" onSubmit={handleSigninFormSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                      
                      <Grid item xs={12}>
                        <GopherTextField
                          required
                          fullWidth
                          id="email"
                          label="Email Address"
                          name="email"
                          type="email"
                          autoComplete="email"
                          value={signinFormValues.email}
                          onChange={handleSigninFormChange} 
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <GopherTextField
                          required
                          fullWidth
                          name="password"
                          label="Password"
                          type="password"
                          id="password"
                          autoComplete="new-password"
                          value={signinFormValues.password}
                          onChange={handleSigninFormChange} 
                        />
                      </Grid>
                    </Grid>
                    <GopherSubmitButton
                      fullWidth
                      loading={apiLoading}
                      variant="contained"
                      buttonText={"Sign In"}
                      sx={{my:2}}
                      
                    />
                    <Grid container justifyContent="center">
                      <Grid item>
                        <Button onClick={() => handleState({forgotPassword:true})}  variant="body2">
                          Forgot Password?
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button onClick={() => handleState({signUp:true})}  variant="body2">
                          Don't have an account? Sign up
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Container>

              <Container component="main"  sx={{
                display: state.forgotPassword?"flex":"none",
                minHeight: '60vh',
               }}  id='signup' maxWidth="xs" >
                <CssBaseline />
                <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                    <img src={logo} alt="Logo" width={120} />
                  
                  <Typography component="h1" variant="h5">
                    Forgot Password
                  </Typography>

                  <Box component="form"  onSubmit={handleForgotPasswordFormSubmit} sx={{ mt: 3, color:theme.BODY_FONT }}>
                 
                        <GopherTextField
                          required
                          fullWidth
                          id="email"
                          label="Email Address"
                          name="email"
                          type="email"
                          value={forgotPasswordFormValues.email}
                          onChange={handleForgotPasswordFormChange} 
                        />
                     
                    <GopherSubmitButton
                      fullWidth
                      loading={apiLoading}
                      variant="contained"
                      buttonText={"Submit"}
                      sx={{my:2}}
                      
                    />
                    <Grid container justifyContent="center">
                      <Grid item>
                        <Button onClick={() => handleState({signIn:true}) }  variant="body2">
                          Go back to Sign in
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>

                </Box>
              </Container>

            </ThemeProvider>
          </Box>
        </Box>
      </Box>
      
      
    </>
  ) 
}

import React from 'react';

import { Box } from '@mui/material';

import Header from '../header/Header';
import HomeBody from './HomeBody';
import Footer from '../footer/Footer';

import * as theme from '../../theme'

export default function Home() {

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header type='home' />
      <Box sx={{ flexGrow: 1, backgroundColor: theme.BODY_BG, color: theme.BODY_FONT }}>
        <HomeBody />
      </Box>
      <Footer />
    </div>
  );
}

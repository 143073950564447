import { Typography, Box, Grid } from '@mui/material'
import TypewriterComponent from 'typewriter-effect'

import logo from '../../logo.png';
import * as theme from '../../theme'

export default function HomeBody() {
  const heading = "We are happy to announce"
  const animatedText = "our great tool!"
  const description = "It helps you get the best answers from ChatGPT by easily building the prompt for you and providing the best possible response."
  
  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: theme.BODY_BG,
        color: theme.BODY_FONT,
      }}
    >
      {/* Desktop version */}
      <Box sx={{ display: { xs: 'none', md: 'block',  }}}>
        <Box>
          <img src={logo} alt="Logo" width={250} />
        </Box>
        <Box>
          <Grid container display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <Grid item sx={{mx:1}}>
              <Typography variant='h3'>
                  {heading}
              </Typography>
            </Grid>
            <Grid item sx={{mx:1}}>
              <Typography variant='h3' color={theme.PRIMARY_BUTTON}>

                  <TypewriterComponent  
                    options={
                      {
                        autoStart: true,
                        loop: true,
                        delay: 50,
                        strings: [animatedText],
                      }
                    }   
                  />
              </Typography> 
            </Grid>
          </Grid>
          
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{py:1}} >
            <Typography variant='h4' width={"100vh"} sx={{ fontFamily: 'sans-serif' }}>
              {description}
            </Typography>
          </Box>

        </Box>
      </Box>
      
      {/* Mobile Version */}
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
     
        <Box>
          <img src={logo} alt="Logo" width={150} />
        </Box>
        <Box sx={{px:1}}>
          <Grid container display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <Grid item sx={{mx:1}}>
              <Typography variant='h4'>
                  {heading}
              </Typography>
            </Grid>
            <Grid item sx={{mx:1}}>
              <Typography variant='h4' color={theme.PRIMARY_BUTTON}>

                  <TypewriterComponent  
                    options={
                      {
                        autoStart: true,
                        loop: true,
                        delay: 50,
                        strings: [animatedText],
                      }
                    }   
                  />
              </Typography> 
            </Grid>
          </Grid>
          
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{py:1}} >
            <Typography variant='h6' width={"100vh"} sx={{ fontFamily: 'sans-serif' }}>
              {description}
            </Typography>
          </Box>

        </Box>
      
      </Box>
      
    </Box>
  ) 
}

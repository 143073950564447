import { useState } from 'react';

import axios from 'axios';


import { Grid, Stack, Typography, Box, Container } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';

import { GopherSelectBox } from '../gopher/select'
import { GopherTextArea } from '../gopher/textarea';
import { GopherActionButton, GopherMainButton } from '../gopher/button';
import { GopherTextField } from '../gopher/field';

import { PROMPT, RESPONSE } from '../../api_endpoints';
import * as theme from '../../theme'
import { GopherAlert } from '../gopher/alert';

import { languageOptions, industryOptions, whoToAskOptions, typeOptions, toneOptions, lengthOptions, formatOptions } from './constants/options';


const updateUserTokens = (user) => {
  const updatedUser = {
    ...user,
    token_balance: parseInt(user.token_balance, 10) - 1,
    token_used: parseInt(user.token_used, 10) + 1,
  }
  return updatedUser
}


export default function NewOrEditPrompt({user, token, setUser, setAPILoading, promptData = {}, promptId_=null}) {
  const [promptId, setPromptId] = useState(promptId_)
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    ...promptData,
    language: promptData.language || user.language,
    industry: promptData.industry || user.industry,
  })
  

  const [alert, setAlert] = useState({
    open: false,
    title: "",
    message: ""
   });
  
   const handleAlertOpen = (title, message) => {
    setAlert({
      open: true,
      title: title,
      message: message
    });
   };
   const handleAlertClose = () => {
    setAlert({
      open: false,
      title: "",
      message: ""
    });
   };
  
  
  const handleFormChange = (event) => {
    setFormData((prevValues) => ({
        ...prevValues,
        [event.target.name]: event.target.value,
    }))
  }
  
  const handleNewOrEditSubmit = (event) => {
    event.preventDefault()
    setLoading(true)
    setAPILoading(true)

    var currentdate = new Date();
    var datetime = currentdate.getFullYear() + ","
                    + (currentdate.getMonth()+1)  + ","
                    + currentdate.getDate() + "," 
                    + currentdate.getHours() + "," 
                    + currentdate.getMinutes() + ","
                    + currentdate.getSeconds();

    const bodyData = {
      ...formData,
      created_at: datetime
    };

    if (promptId === null){
      axios.post(
          PROMPT, 
          {
              "prompt_data": bodyData
          }, 
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token
            }
          }
      )
      .then(response => response.data)
      .then(data => {
        setLoading(false)
        setAPILoading(false)
        window.scrollTo(0, document.body.scrollHeight);
        setFormData(
          data.data
        )
        setPromptId(data.prompt_id)
        const uu = updateUserTokens(user)
        console.log(uu)
        setUser(uu)
      })
      .catch(error => {
        setLoading(false)
        setAPILoading(false)
        const errorResponse = error.response
        handleAlertOpen("Error", errorResponse!==undefined?errorResponse.data.message:error.message)
      });
    }
    else {
      axios.put(
        PROMPT, 
        {
            "prompt_id": promptId,
            "prompt_data": bodyData,
        }, 
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }
        }
      )
      .then(response => response.data)
      .then(data => {
        setLoading(false)
        setAPILoading(false)
        window.scrollTo(0, document.body.scrollHeight);
        setFormData(
          data.data
        ) 
        const uu = updateUserTokens(user)
        setUser(uu)
      })
      .catch(error => {
        setLoading(false)
        setAPILoading(false)
        const errorResponse = error.response
        handleAlertOpen("Error", errorResponse!==undefined?errorResponse.data.message:error.message)
      });
    }

  
  }


  const handleCopyToClipboard = () => {
    const textToCopy = formData.response;
  
    const hiddenElement = document.createElement('div');
    hiddenElement.innerText = textToCopy;
    hiddenElement.style.fontFamily = 'Arial';
    hiddenElement.style.fontSize = '13px';
    hiddenElement.style.position = 'absolute';
    hiddenElement.style.left = '-9999px';
    hiddenElement.style.whiteSpace = 'pre-wrap';
  
    document.body.appendChild(hiddenElement);
    const range = document.createRange();
    range.selectNode(hiddenElement);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
    document.body.removeChild(hiddenElement);
  
    handleAlertOpen('Success', 'Copied to clipboard');
  };
  
  
  const handleLike = () => {
    setLoading(true)
    setAPILoading(true)

    axios.put(
      RESPONSE, 
      {
          "prompt_id": promptId,
      }, 
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }
    )
    .then(response => response.data)
    .then(data => {
      setLoading(false)
      setAPILoading(false)
      window.scrollTo(0, document.body.scrollHeight);

      const updatedFormData = { ...formData };
      updatedFormData.liked = !formData.liked
      setFormData(updatedFormData);

    })
    .catch(error => {
      setLoading(false)
      setAPILoading(false)
      const errorResponse = error.response
      handleAlertOpen("Error", errorResponse!==undefined?errorResponse.data.message:error.message)
    });
  }


  return (
    <>
    <GopherAlert alert={alert} handleAlertClose={handleAlertClose} />
    <Grid container display={'flex'} justifyContent={'center'} sx={{px: 10, backgroundColor: theme.TABLE_BG}}>
      
      {/* Desktop version */}
      <Box sx={{ display: { xs: 'none', md: 'flex',  } }}>
        {/* Input  */}
        <Grid item xs={5} sx={{ px:5, py:5 }} >
          <Box component="form"  onSubmit={handleNewOrEditSubmit} >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <GopherSelectBox label="Language" tooltipTitle={"The language used in the Get topic to communicate with the GopherGet GPT AI chat."}  name="language" value={formData.language} onChange={handleFormChange}  sx={{backgroundColor: theme.BODY_BG}} options={languageOptions} required />
              </Grid>
              <Grid item xs={12} sm={6}>
                <GopherSelectBox label="Industry" tooltipTitle={"The industry can vary depending on the context of the conversation. For example, if the conversation is about marketing, the industry could be advertising, digital marketing, or similar."} name="industry" value={formData.industry} onChange={handleFormChange}  options={industryOptions} />
              </Grid>
              <Grid item xs={12} >
                <GopherTextArea label="Topic" name="topic" value={formData.topic} onChange={handleFormChange}  sx={{backgroundColor: theme.BODY_BG}} required />
              </Grid>
              <Grid item xs={12} sm={6}>
                <GopherSelectBox label='Who to ask'  name="who_to_ask" tooltipTitle={"This refers to the specific AI personality that would be best suited to generate content for the Get. For example, if the conversation is about customer service, the AI personality should be friendly and helpful: a seasoned customer service specialist. A recipe Get is improved by asking a 'Chef' or a 'Michelin Star kitchen staff'."} value={formData.who_to_ask} onChange={handleFormChange}  options={whoToAskOptions} />
              </Grid>
              <Grid item xs={12} sm={6}>
                
                <GopherTextField sx={{width: '100%'}} name="audience" tooltipTitle={"This refers to the intended audience for the content. It could be a specific demographic (e.g. young adults), a particular profession (e.g. lawyers), or a general audience; customers, colleagues, or stakeholders. For personal Gets, the field can be left blank, filled 'User' or framed to your current circumstances for the Get. The language and tone should be tailored to the audience."}
  value={formData.audience} onChange={handleFormChange}  label="Audience"  />
              </Grid>
              <Grid item xs={12} sm={6}>
                <GopherSelectBox label="Type" name="type" tooltipTitle={"This refers to the type of content or communication that the Get is asking for. The type of conversation could be a casual chat, a business meeting, or a customer service interaction. More examples include instructional, open-ended, close-ended, persuasive and creative. It could more specific such as a: blog post, social media update, an email, etc. "} value={formData.type} onChange={handleFormChange}  options={typeOptions} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <GopherSelectBox label="Tone" name="tone" tooltipTitle={"This refers to the overall mood or attitude that the content should convey. It could be serious, playful, informative, etc.  The tone of the conversation should be appropriate for the context and audience. For example, a casual chat could have a friendly and informal tone, while a business meeting should have a professional and formal tone."} value={formData.tone} onChange={handleFormChange}  options={toneOptions} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <GopherSelectBox label="Length" name="length" tooltipTitle={"This refers to the desired length of the content. It could be a specific word count, a range of words, or a general guideline. A casual chat could be short and sweet, while a business meeting could be longer and more detailed."}
 value={formData.length} onChange={handleFormChange}  options={lengthOptions} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <GopherSelectBox label="Format" name="format" tooltipTitle={"This refers to the specific format that the content should be in. It could be bulleted points or paragraphs. It could be specific platform format, writing style or convention (such as a letter, or a memo), or any additional structure to the Get."}
 value={formData.format} onChange={handleFormChange}  options={formatOptions} />
              </Grid>
              <Grid item sx={{mt:3}} xs={12}>
                <GopherMainButton type='submit' buttonText="Get Response" loading={loading} />
              </Grid>  
              
            </Grid>
          </Box>
        </Grid>
        
        {/* Response  */}
        <Grid item xs={7} sx={{ px:5, py:5}} >
          <Grid container>
            <Grid item xs={12} display={'flex'} justifyContent={'left'} alignItems={'left'}>
              <Typography variant='h5' >
                Response
              </Typography>
            </Grid>
            <Grid item sx={{my:1}} xs={12}>
              <GopherTextArea value={formData.response} readOnly rows={15} />
            </Grid>
            <Grid item sx={{my:1}} xs={12}>
              <Stack direction={'row'} spacing={2}>
                <GopherActionButton onClick={handleCopyToClipboard} startIcon={<ContentCopyIcon />} buttonText="Copy To Clipboard" />
                {formData.response && <GopherActionButton onClick={handleLike} startIcon={formData.liked?<ThumbUpAltIcon />:<ThumbUpOffAltIcon />} buttonText={formData.liked?"Dislike":"Like"} />}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {/* Mobile Version */}
      <Box sx={{ display: { xs: 'block', md: 'none',  } }}>
        {/* Input  */}
        <Container component="main"  sx={{
                display: "flex",
                minHeight: '60vh',
                padding:2
               }}  id='signup' maxWidth="md" >
          <Box display={'inline-flex'} alignItems={'center'} justifyContent={'center'} sx={{  backgroundColor: theme.BODY_BG, color: theme.BODY_FONT, width: '40vh'}}> 
            <Box component="form"  onSubmit={handleNewOrEditSubmit} sx={{ color:theme.BODY_FONT }}>
              <Grid container spacing={2}>
                <Grid item xs={12} >
                  <GopherSelectBox label="Language" name="language" value={formData.language} onChange={handleFormChange}  sx={{backgroundColor: theme.BODY_BG}} options={languageOptions} required />
                </Grid>
                <Grid item xs={12} >
                  <GopherSelectBox label="Industry" name="industry" value={formData.industry} onChange={handleFormChange}  options={industryOptions} />
                </Grid>
                <Grid item xs={12} >
                  <GopherTextArea label="Topic" name="topic" value={formData.topic} onChange={handleFormChange}  sx={{backgroundColor: theme.BODY_BG}} required />
                </Grid>
                <Grid item xs={12}>
                  <GopherSelectBox label='Who to ask' name="who_to_ask" value={formData.who_to_ask} onChange={handleFormChange}  options={whoToAskOptions} />
                </Grid>
                <Grid item xs={12}>
                  
                  <GopherTextField sx={{width: '100%'}} name="audience" value={formData.audience} onChange={handleFormChange}  label="Audience"  />
                </Grid>
                <Grid item xs={12}>
                  <GopherSelectBox label="Type" name="type" value={formData.type} onChange={handleFormChange}  options={typeOptions} />
                </Grid>
                <Grid item xs={12}>
                  <GopherSelectBox label="Tone" name="tone" value={formData.tone} onChange={handleFormChange}  options={toneOptions} />
                </Grid>
                <Grid item xs={12} >
                  <GopherSelectBox label="Length" name="length" value={formData.length} onChange={handleFormChange}  options={lengthOptions} />
                </Grid>
                <Grid item xs={12} >
                  <GopherSelectBox label="Format" name="format" value={formData.format} onChange={handleFormChange}  options={formatOptions} />
                </Grid> 
                <Grid item sx={{mt:3}} xs={12}>
                  <GopherMainButton type='submit' buttonText="Get Response" loading={loading} />
                </Grid>  
                
              </Grid>
            </Box>
          </Box>
        </Container>
    
        
        {/* Response  */}
        <Grid item xs={12} sx={{ mt: 5}} >
          <Grid container>
            <Grid item xs={12} display={'flex'} justifyContent={'left'} alignItems={'left'}>
              <Typography variant='h5' >
                Response
              </Typography>
            
            </Grid>
            
            <Grid item sx={{my:1}} xs={12}>
              
              <GopherTextArea value={formData.response || 'No Response'} readOnly rows={15} />
            </Grid>
            <Grid item sx={{my:1}} xs={12}>
              <Stack display={'flex'} justifyContent={'left'} alignItems={'left'} spacing={2}>
                <GopherActionButton onClick={handleCopyToClipboard} startIcon={<ContentCopyIcon />} buttonText="Copy" />
                { formData.response && <GopherActionButton onClick={handleLike} startIcon={formData.liked?<ThumbUpAltIcon />:<ThumbUpOffAltIcon />} buttonText={formData.liked?"Dislike":"Like"} />}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
    </>
  )
}

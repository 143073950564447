export const languageOptions = [
    { label: 'English', value: 'English' },
    { label: 'Spanish', value: 'Spanish' },
    { label: 'Chinese', value: 'Chinese' },
    { label: 'French', value: 'French' },
  ];
  
 export const industryOptions = [
    { label: 'Advertising', value: 'Advertising' },
    { label: 'Agriculture', value: 'Agriculture' },
    { label: 'Apparel', value: 'Apparel' },
    { label: 'Automotive', value: 'Automotive' },
    { label: 'Banking', value: 'Banking' },
    { label: 'Biotech', value: 'Biotech' },
    { label: 'Chemicals', value: 'Chemicals' },
    { label: 'Communications', value: 'Communications' },
    { label: 'Computer hardware', value: 'Computer hardware' },
    { label: 'Computer software', value: 'Computer software' },
    { label: 'Construction', value: 'Construction' },
    { label: 'Consumer electronics', value: 'Consumer electronics' },
    { label: 'Consumer goods', value: 'Consumer goods' },
    { label: 'Cosmetics', value: 'Cosmetics' },
    { label: 'DIY', value: 'DIY' },
    { label: 'Defense', value: 'Defense' },
    { label: 'Education', value: 'Education' },
    { label: 'Electronics', value: 'Electronics' },
    { label: 'Energy', value: 'Energy' },
    { label: 'Entertainment', value: 'Entertainment' },
    { label: 'Environmental', value: 'Environmental' },
    { label: 'Fashion', value: 'Fashion' },
    { label: 'Financial services', value: 'Financial services' },
    { label: 'Food and beverage', value: 'Food and beverage' },
    { label: 'Gaming', value: 'Gaming' },
    { label: 'Government', value: 'Government' },
    { label: 'Healthcare', value: 'Healthcare' },
    { label: 'Hospitality', value: 'Hospitality' },
    { label: 'Human resources', value: 'Human resources' },
    { label: 'Information technology', value: 'Information technology' },
    { label: 'Insurance', value: 'Insurance' },
    { label: 'Internet', value: 'Internet' },
    { label: 'Investment banking', value: 'Investment banking' },
    { label: 'Legal', value: 'Legal' },
    { label: 'Logistics', value: 'Logistics' },
    { label: 'Manufacturing', value: 'Manufacturing' },
    { label: 'Marketing', value: 'Marketing' },
    { label: 'Media', value: 'Media' },
    { label: 'Medical devices', value: 'Medical devices' },
    { label: 'Mining', value: 'Mining' },
    { label: 'Music', value: 'Music' },
    { label: 'Nonprofit', value: 'Nonprofit' },
    { label: 'Oil and gas', value: 'Oil and gas' },
    { label: 'Online retail', value: 'Online retail' },
    { label: 'Pharmaceuticals', value: 'Pharmaceuticals' },
    { label: 'Photography', value: 'Photography' },
    { label: 'Printing', value: 'Printing' },
    { label: 'Professional services', value: 'Professional services' },
    { label: 'Project Management', value: 'Project Management' },
    { label: 'Publishing', value: 'Publishing' },
    { label: 'Real estate', value: 'Real estate' },
    { label: 'Recreation', value: 'Recreation' },
    { label: 'Retail', value: 'Retail' },
    { label: 'Sales', value: 'Sales' },
    { label: 'Science', value: 'Science' },
    { label: 'Security', value: 'Security' },
    { label: 'Shipping', value: 'Shipping' },
    { label: 'Social media', value: 'Social media' },
    { label: 'Sports', value: 'Sports' },
    { label: 'Telecommunications', value: 'Telecommunications' },
    { label: 'Textiles', value: 'Textiles' },
    { label: 'Tourism', value: 'Tourism' },
    { label: 'Transportation', value: 'Transportation' },
    { label: 'Travel', value: 'Travel' },
    { label: 'Utilities', value: 'Utilities' },
    { label: 'Venture capital', value: 'Venture capital' },
    { label: 'Video production', value: 'Video production' },
    { label: 'Web design', value: 'Web design' },
    { label: 'Web development', value: 'Web development' },
    { label: 'Wine and spirits', value: 'Wine and spirits' },
    { label: 'Wireless', value: 'Wireless' },
    { label: 'Writing and editing', value: 'Writing and editing' },
    { label: 'Aerospace', value: 'Aerospace' },
    { label: 'Architecture', value: 'Architecture' },
    { label: 'Art', value: 'Art' },
    { label: 'Aviation', value: 'Aviation' },
    { label: 'Beauty', value: 'Beauty' },
    { label: 'Chemical engineering', value: 'Chemical engineering' },
    { label: 'Civil engineering', value: 'Civil engineering' },
    { label: 'Commercial real estate', value: 'Commercial real estate' },
    { label: 'Consulting', value: 'Consulting' },
    { label: 'Consumer services', value: 'Consumer services' },
    { label: 'Corporate law', value: 'Corporate law' },
    { label: 'Creative services', value: 'Creative services' },
    { label: 'Cybersecurity', value: 'Cybersecurity' },
    { label: 'Data analytics', value: 'Data analytics' },
    { label: 'Dental', value: 'Dental' },
    { label: 'Ecommerce', value: 'Ecommerce' },
    { label: 'Electrical engineering', value: 'Electrical engineering' },
    { label: 'Employment services', value: 'Employment services' },
    { label: 'Engineering', value: 'Engineering' },
    { label: 'Event planning', value: 'Event planning' },
    { label: 'Executive search', value: 'Executive search' },
    { label: 'Facilities management', value: 'Facilities management' },
    { label: 'Financial planning', value: 'Financial planning' },
    { label: 'Fitness', value: 'Fitness' },
    { label: 'Freight forwarding', value: 'Freight forwarding' },
    { label: 'Graphic design', value: 'Graphic design' },
    { label: 'Healthcare IT', value: 'Healthcare IT' },
    { label: 'Home improvement', value: 'Home improvement' },
    { label: 'Humanitarian aid', value: 'Humanitarian aid' },
  ];
  
 export const whoToAskOptions = [
    { label: "Author", value: "Author" },
    { label: "Doctor", value: "Doctor" },
    { label: "Real Estate Agent", value: "Real Estate Agent" },
    { label: "Professional", value: "Professional" },
    { label: "Chief Marketing Officer", value: "Chief Marketing Officer" },
    { label: "Attorney", value: "Attorney" },
    { label: "Minecraft Expert", value: "Minecraft Expert" }
  ];
  
 export const typeOptions = [
    { label: 'Open-Ended', value: 'Open-Ended' },
    { label: 'Closed-ended', value: 'Closed-ended' },
    { label: 'Informational', value: 'Informational' },
    { label: 'Persuasive', value: 'Persuasive' },
    { label: 'Reflective', value: 'Reflective' },
    { label: 'Creative', value: 'Creative' },
  ];
  
 export const toneOptions = [
    { label: 'Professional (Formal)', value: 'Professional (Formal)' },
    { label: 'Conversational (Informal)', value: 'Conversational (Informal)' },
    { label: 'Academic', value: 'Academic' },
    { label: 'Humorous', value: 'Humorous' },
    { label: 'Sarcastic', value: 'Sarcastic' },
    { label: 'Serious', value: 'Serious' },
    { label: 'Playful', value: 'Playful' },
    { label: 'Authoritative', value: 'Authoritative' },
    { label: 'Empathetic', value: 'Empathetic' },
    { label: 'Sympathetic', value: 'Sympathetic' },
    { label: 'Optimistic', value: 'Optimistic' },
    { label: 'Pessimistic', value: 'Pessimistic' },
    { label: 'Cynical', value: 'Cynical' },
    { label: 'Hopeful', value: 'Hopeful' },
    { label: 'Descriptive', value: 'Descriptive' },
    { label: 'Narrative', value: 'Narrative' },
    { label: 'Expository', value: 'Expository' },
    { label: 'Argumentative', value: 'Argumentative' },
    { label: 'Critical', value: 'Critical' },
    { label: 'Satirical', value: 'Satirical' },
    { label: 'Ironical', value: 'Ironical' },
    { label: 'Poetic', value: 'Poetic' },
  ]
  
 export const lengthOptions = [
    { label: 'Sentence', value: 'Sentence' },
    { label: 'Short response', value: 'Short response' },
    { label: 'Paragraph', value: 'Paragraph' },
    { label: 'Long response', value: 'Long response' },
    { label: 'Essay', value: 'Essay' },
    { label: 'Research paper', value: 'Research paper' },
    { label: 'Oneliner', value: 'Oneliner' },
    { label: 'Brief explanation', value: 'Brief explanation' },
    { label: 'Detailed explanation', value: 'Detailed explanation' },
    { label: 'List of bullet points', value: 'List of bullet points' },
    { label: 'Personal anecdote', value: 'Personal anecdote' },
    { label: 'Historical context', value: 'Historical context' },
    { label: 'Statistical analysis', value: 'Statistical analysis' },
    { label: 'Comparative analysis', value: 'Comparative analysis' },
    { label: 'Philosophical reflection', value: 'Philosophical reflection' },
    { label: 'Creative writing piece', value: 'Creative writing piece' },
    { label: 'Technical explanation', value: 'Technical explanation' },
    { label: 'Step-by-step guide', value: 'Step-by-step guide' },
    { label: 'Q&A format', value: 'Q&A format' },
    { label: 'Interview-style response', value: 'Interview-style response' },  
  ]
  
 export const formatOptions = [
    {
      label: "Bullet List",
      value: "Bullet List",
    },
    {
      label: "Paragraphs",
      value: "Paragraphs",
    },
  ]
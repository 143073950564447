export const HEADER_BG = "#282c34"
export const HEADER_FONT = "#ffffff"
export const FOOTER_BG = "#282c34"
export const FOOTER_FONT = "#ffffff"

export const BODY_BG = "#202124"
export const BODY_FONT = "#ffffff"

export const TABLE_BG = "#282c34"
export const TABLE_FONT = "#ffffff"

export const TEXT_FIELD_BORDER = "#ffffff"
export const TEXT_FIELD_HOVER = "#ffffff"
export const TEXT_FIELD_FOCUS = "#CA8154"
export const TEXT_FIELD_LABEL = "#CA8154"

export const PRIMARY_BUTTON = "#CA8154"
export const PRIMARY_BUTTON_FONT = "#ffffff"

export const SUBSCRIBED_BUTTON = "#2AB06F"
export const SUBSCRIBE_BUTTON = PRIMARY_BUTTON
export const SUBSCRIBE_BUTTON_FONT = PRIMARY_BUTTON_FONT
export const SUBSCRIBED_BUTTON_FONT = "#ffffff"

export const SECONDRY_BUTTON = "#ffffff"
export const SECONDRY_BUTTON_FONT = "#000000"

export const MAIN_BUTTON = "#ffffff"
export const MAIN_BUTTON_FONT = "#000000"

export const TITLE_BG = "#202124"
export const TITLE_FONT = "#ffffff"

export const WARNING_BG = "#191207"
export const WARNING_FONT = "#D9C09A"

export const SUCCESS_BG = "#198754"
export const SUCCESS_FONT = "#ffffff"
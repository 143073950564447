import React from 'react';
import { GopherTextField } from '../field';

const GopherTextArea = (props) => {
  const {rows=3, ...rest} = props
  return (
    <GopherTextField
      {...rest}
      sx=
      {{width: '100%'}}
      multiline
      rows={rows}
    />
  );
};

export default GopherTextArea;

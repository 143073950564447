import React from 'react';

import { Box } from '@mui/material';

import Header from '../header/Header';
import Footer from '../footer/Footer';
import AuthenticationBody from './AuthenticationBody';

import * as theme from '../../theme'
import ResetPassword from './ResetPassword';


export default function Authentication(props) {

  const { resetPassword, ...rest } = props

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header type='auth' />
      <Box sx={{ flexGrow: 1, backgroundColor: theme.BODY_BG, color: theme.BODY_FONT, display: 'flex', justifyContent: 'center', alignItems: 'center',}}> 
        {
          resetPassword?
          <ResetPassword {...rest} />:
          <AuthenticationBody {...rest} />
        }
      </Box>
      <Footer />
    </div>
  )
}

import React from 'react';
import { Autocomplete, TextField, Tooltip } from '@mui/material';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';

import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import * as theme from '../../../theme'


const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    
    components: {
      MuiAutocomplete: {
        styleOverrides: {
          input: {
            color: theme.BODY_FONT,
          },
          clearIndicator: {
            color: theme.BODY_FONT,
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '--TextField-brandBorderColor': theme.TEXT_FIELD_BORDER,
            '--TextField-brandBorderHoverColor': theme.TEXT_FIELD_HOVER,
            '--TextField-brandBorderFocusedColor': theme.TEXT_FIELD_FOCUS,
            '& label.Mui-focused': {
              color: 'var(--TextField-brandBorderFocusedColor)',
            },
           
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: 'var(--TextField-brandBorderColor)',
          },
          root: {
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'var(--TextField-brandBorderHoverColor)',
            
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'var(--TextField-brandBorderFocusedColor)', 
            },
            
          },
        },
      },
      
     
    },
  });



const GopherSelectBox = (props) => {
  const { name, options, value, onChange,tooltipTitle="", ...rest } = props;
  
  const outerTheme = useTheme();

  return (
    <ThemeProvider theme={customTheme(outerTheme)}>
      <Tooltip title={tooltipTitle} style={{ fontSize: '20px' }}>

        <Autocomplete
    freeSolo
    options={options}
    value={value}
    onChange={(event, newValue) => {
      const event_target = {
        target: {
          name: name,
          value: newValue?newValue.value: ""
        }
      }
      onChange(event_target)
    }}
    renderInput={(params) => (
      <TextField
        {...params}
        name={name}
        value={value}
        onChange={onChange}
        {...rest}
        InputLabelProps={{
          sx: { color: theme.TEXT_FIELD_LABEL },
        }}
      />
    )}
  />
      </Tooltip>
    </ThemeProvider>
  );
};

export default GopherSelectBox;
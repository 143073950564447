import React, { useState } from 'react'

import { Alert, Box, Container, Grid } from '@mui/material'

import { useNavigate } from 'react-router-dom'

import Header from '../../header/Header'
import Footer from '../../footer/Footer'

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Title from '../Title'

import { GopherActionButton, GopherMobileActionButton, GopherSubmitButton } from '../../gopher/button'
import * as theme from '../../../theme'
import { GopherTextField } from '../../gopher/field'

import { ACCOUNT } from '../../../api_endpoints'
import axios from 'axios'
import { GopherAlert } from '../../gopher/alert'

import { languageOptions, industryOptions } from '../constants/options'
import { GopherSelectBox } from '../../gopher/select'

export default function Account({user, token, removeToken, removeUser, setLoading, setUser}) {
  const navigate = useNavigate()
  const [apiLoading, setAPILoading] = useState(false)
  const [accountValues, setAccountValues] = useState(user)
  const [alert, setAlert] = useState({
    open: false,
    title: "",
    message: ""
   });
  
   const handleAlertOpen = (title, message) => {
    setAlert({
      open: true,
      title: title,
      message: message
    });
   };

   const handleAlertClose = () => {
    setAlert({
      open: false,
      title: "",
      message: ""
    });
   };


  const handleAccountSubmit = (event) => {
    event.preventDefault()
    setAPILoading(true)

    axios.post(
      ACCOUNT, 
        {
            "email": accountValues.email,
            "language": accountValues.language,
            "industry": accountValues.industry
        }, 
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }
        }
    )
    .then(response => response.data)
    .then(data => {
        const userData = data.user
        setUser(userData)
        setAPILoading(false)
        handleAlertOpen("Success", "Changes have been saved")
    })
    .catch(error => {
      setAPILoading(false)
      const errorResponse = error.response
      handleAlertOpen("Error", errorResponse!==undefined?errorResponse.data.message:error.message)
    });
  
   }
  

  const handleAccountChange = (event) => {
    setAccountValues((prevValues) => ({
      ...prevValues,
      [event.target.name]: event.target.value,
  }))
}
  const handleUpdatePlan = () => {
    navigate("/payment")
  }
  
  const tokenBalance = parseInt(user.token_balance, 10)
  const isTokenBalanceGreaterThanOne = tokenBalance > 1;

  return (
    <>
    <GopherAlert alert={alert} handleAlertClose={handleAlertClose} />
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header user={user} type='prompt' removeToken={removeToken} removeUser={removeUser} setLoading={setLoading} />
      <Box sx={{ flexGrow: 1, backgroundColor: theme.BODY_BG, color: theme.BODY_FONT, pb: 5}} >
        <Title text={"Your Account"} buttons={[<GopherActionButton onClick={() => { navigate("/prompt") }} cursor="pointer" buttonText={"Back to Prompts"} startIcon={<ArrowBackIcon />} key={1} sx={{ml:1}} />]} mobileButtons={[<GopherMobileActionButton onClick={() => { navigate("/prompt") }} cursor="pointer" buttonText={"Back"} startIcon={<ArrowBackIcon />} key={1} sx={{ml:1}} />]} />

        
        {/* Account details */}
        <Container component="main"  sx={{
                display: "flex",
                minHeight: '60vh',
               }}  id='signup' maxWidth="xs" >
        
        <Box display={'inline-flex'} alignItems={'center'} justifyContent={'center'} sx={{  backgroundColor: theme.BODY_BG, color: theme.BODY_FONT, width: '50vh'}}> 
        
          <Box component="form"  onSubmit={handleAccountSubmit} sx={{ mt: 3, color:theme.BODY_FONT }}>
            
            <Grid container  spacing={2}>
              <Grid item xs={12} sm={6}>
                <GopherTextField
                  autoComplete="given-name"
                  name="first_name"
                  fullWidth
                  id="firstName"
                  readOnly
                  label="First Name"
                  value={accountValues.first_name}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <GopherTextField
                  fullWidth
                  id="last_name"
                  label="Last Name"
                  name="lastName"
                  readOnly
                  autoComplete="family-name"
                  value={accountValues.last_name}
                />
              </Grid>
              <Grid item xs={12}>
                <GopherTextField
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  readOnly
                  autoComplete="email"
                  value={accountValues.email}
                />
              </Grid>
              <Grid item xs={12}>
                
              <Grid container spacing={4} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Grid item={11}>
                  <GopherTextField
                    fullWidth
                    id="subscription_plan"
                    label="Subscription Plan"
                    name="subscription_plan"
                    readOnly
                    autoComplete="subscription_plan"
                    value={(!accountValues.subscription_plan || accountValues.subscription_plan==="null") ? "Free Version":accountValues.subscription_plan}
                  />
                </Grid>
                <Grid item={2}>
                  <GopherActionButton
                    buttonText={"Update Plan"}
                    onClick={handleUpdatePlan}
                  />
                </Grid>
              </Grid>
              </Grid>
              <Grid item xs={12}>
                <GopherSelectBox
                  label="Language" 
                  name="language" 
                  value={accountValues.language} 
                  onChange={handleAccountChange}  
                  sx={{backgroundColor: theme.BODY_BG}} 
                  options={languageOptions} 
                  required fullWidth />
              </Grid>
              <Grid item xs={12}>
                <GopherSelectBox
                  label="Industry"
                  name="industry"
                  value={accountValues.industry}
                  onChange={handleAccountChange} 
                  sx={{backgroundColor: theme.BODY_BG}} 
                  options={industryOptions} 
                  fullWidth />
              </Grid>
              <Grid item xs={12}>
                <GopherTextField
                  fullWidth
                  id="token_used"
                  label="Token Used"
                  name="token_used"
                  autoComplete="token_used"
                  value={accountValues.token_used}
                  readOnly
                />
              </Grid>
              
              {
                !isTokenBalanceGreaterThanOne &&
                <Grid item xs={12}>
                  <Alert sx={{ backgroundColor:theme.WARNING_BG, color:theme.WARNING_FONT }} severity="warning">
                  {
                    tokenBalance === 0?
                    "Sorry! You dont have any tokens":
                    "You have only one token left!"
                  }
                  </Alert>
                </Grid>
              }
              
              <Grid item xs={12}>
                <GopherTextField
                  fullWidth
                  id="token_balance"
                  label="Token Balance"
                  name="token_balance"
                  autoComplete="token_balance"
                  readOnly
                  value={accountValues.token_balance}
                />
              </Grid>
            </Grid>
            <GopherSubmitButton
              loading={apiLoading}
              variant="contained"
              buttonText={"Save Changes"}
              sx={{my:2}}
              
            />
            
          </Box>

        </Box>
        </Container>
      </Box>  
      <Footer disclaimer />
    </div>
    </>
  )
}

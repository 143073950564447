import React, { useState } from 'react'

import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'

import useToken from './userToken'
import useData from './userData'
import Home from './components/home/Home'
import Authentication from './components/auth/Authentication'
import Prompt from './components/main/Prompt'
import { GopherLoadingMain } from './components/gopher/loading'
import Account from './components/main/account/Account'
import Payment from './components/main/payment/Payment'

function App() {

  const [ loading, setLoading ] = useState({
    state: false,
    headline: "",
    paragraph: ""
  })
  const { token, removeToken, setToken } = useToken()
  const { user, removeUser, setUser } = useData()

  return (
    <div style={{ textAlign: "center", minHeight: "100vh", color: "white" }}>
    {
      loading.state? 

      <GopherLoadingMain headline={loading.headline} paragraph={loading.paragraph} />: 

      <Router throttle={0}>
          {
                !token && token!=="" && token!== undefined?
                <Routes>
                  <Route excat path="/" element={<Home />} />
                  <Route excat path="/auth" element={<Authentication setLoading={setLoading} setToken={setToken} setUser={setUser} />} />
                  <Route excat path="/reset-password/:uid64/:resetToken" element={<Authentication resetPassword setLoading={setLoading} setToken={setToken} setUser={setUser} />} />
                  <Route path="*" element={<Navigate to="/" />} />
              </Routes>:
              
              <Routes>
                  <Route excat path="/prompt" element={<Prompt user={user} token={token}  removeToken={removeToken}  setUser={setUser} removeUser={removeUser} setLoading={setLoading} />} />
                  <Route excat path="/prompt/new" element={<Prompt user={user} token={token}  removeToken={removeToken}  setUser={setUser} removeUser={removeUser} setLoading={setLoading} newPrompt />} />
                  <Route excat path="/account" element={<Account user={user} token={token}  removeToken={removeToken} removeUser={removeUser} setUser={setUser} setLoading={setLoading} />} />
                  <Route excat path="/payment" element={<Payment user={user} setUser={setUser} token={token}  removeToken={removeToken} removeUser={removeUser} setLoading={setLoading} />} />
                  <Route path="*" element={<Navigate to="/prompt" />} />
                </Routes>
          }
        </Router> 
    }
     
    </div>
  );
}

export default App;
